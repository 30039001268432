import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';

interface PrizeList {
  peolaud: Pick<any, string | number | symbol>[];
}

interface Props {
  winnersModalActive: boolean;
  setWinnersModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Winners: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    peolaud: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');

    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      peolaud: prizes.peolaud === undefined ? [] : prizes.peolaud,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <div
      id="winners-modal"
      className={props.winnersModalActive ? 'modal is-active' : 'modal'}
    >
      <div className="modal-background"></div>
      <div className="modal-content">
        <h2>{t('winners')}</h2>
        <div className="winners-wrapper columns">
          <div className="column">
            <ul className={winners.peolaud.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('prize 1 winners')}</li>
              {/*winners.peolaud.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })*/}
            </ul>
          </div>
        </div>
        <div className="close-btn-wrapper">
          <button
            className="button"
            onClick={() =>
              props.setWinnersModalActive(!props.winnersModalActive)
            }
          >
            {t('close')}
          </button>
        </div>
      </div>
      <div></div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => props.setWinnersModalActive(!props.winnersModalActive)}
      ></button>
    </div>
  );
};

export default Winners;
