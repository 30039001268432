import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Kampaania reeglid</h2>
      <ol>
        <li>
          Fazeri Must kampaania on{' '}
          <span className="bold">alates 01.02.2024 kuni 29.02.2024</span> toimuv
          tarbijamäng (edaspidi kampaania), mis leiab aset{' '}
          <span className="bold">
            kõigis Eesti Vabariigi territooriumil asuvates kauplustes
          </span>
          , kus kampaanias osalevaid tooteid müüakse.
        </li>
        <li>
          Kampaania ja auhinnad korraldab:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5.korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
      <h3>OSALEMINE ja AUHINNAD</h3>
      <ol>
        <li>
          Kampaania info on kättesaadav aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a>{' '}
        </li>
        <li>
          Kampaania auhinna loosimises osalemiseks tuleb osta{' '}
          <span className="bold">ükskõik millisest kauplusest üle-Eesti </span>{' '}
          vähemalt üks kampaanias osalev{' '}
          <span className="bold">
            Fazer Must brändi toode ja registreerida ostutšeki number{' '}
          </span>{' '}
          veebilehel <a href="https://www.fazer.ee/">https://www.fazer.ee/</a>{' '}
          ajavahemikus <span className="bold">01.02 – 29.02.2024</span> (k.a).
        </li>
        <li>
          Auhinnafondi <span className="bold">koguväärtus on 2024 eurot</span>.
          Auhinnafondis on{' '}
          <span className="bold">2024 euro väärtuses peolaua krediit</span>,
          mille võitja peab ära kasutama 2024 aasta lõpuks. Peolaua korraldab{' '}
          <span className="bold">Ribe catering</span> (Meelte Maitsed OÜ) ning
          auhind sisaldab{' '}
          <span className="bold">toitlustust ja/või catering teenust </span>{' '}
          vastavalt võitja soovidele. Auhind ei sisalda alkohoolseid jooke ega
          peo toimumiskohta ning sellega seotud kulusid. Ürituse kuupäev tuleb
          eelnevalt peolaua korraldaja, Ribe cateringiga kokku leppida.
          Korraldaja kontaktid edastab Fazer Eesti OÜ auhinna võitjale.
        </li>
        <li>
          Kampaanias osaleja peab registreeritud ostutšeki alles hoidma
          kampaania lõpuni, et esitada see võimaliku võidu kättesaamiseks.
        </li>
        <li>Ühte ostutšekki saab kampaania jooksul registreerida ühe korra.</li>
        <li>
          Osaleja võib teha mitu registreerimist, kui ostab kampaanias osalevaid
          tooteid mitu korda ja registreerib iga kord uue ostutšeki numbri.
        </li>
        <li>
          Kampaanias osalevad tooted on:
          <table>
            <tbody>
              <tr>
                <td>EAN</td>
                <td>Toote nimetus</td>
              </tr>
              <tr>
                <td>4740103020210</td>
                <td>Fazeri Must leib 300g</td>
              </tr>
              <tr>
                <td>4740103020203</td>
                <td>Fazeri Must leib 600g</td>
              </tr>
              <tr>
                <td>4740103020777</td>
                <td>Fazer Must Pidupäevaleib 500g</td>
              </tr>
              <tr>
                <td>4740103020760</td>
                <td>Fazeri Must põrandaleib 390g</td>
              </tr>
              <tr>
                <td>4740103020845</td>
                <td>Fazer Must põrandaleib seemnete ja teradega 390 g</td>
              </tr>
              <tr>
                <td>4740103020609</td>
                <td>Fazer Must seemneleib 280g</td>
              </tr>
              <tr>
                <td>4740103021125</td>
                <td>Fazer Must Näkileib päevalilleseemnetega 500g</td>
              </tr>
              <tr>
                <td>4740103021101</td>
                <td>Must Näkileib päevalilleseemnetega 150g</td>
              </tr>
              <tr>
                <td>4740103021163</td>
                <td>Must Näkileib küüslaugu ja peterselliga 150g</td>
              </tr>
            </tbody>
          </table>
        </li>
        <h3>VÕITJATE LOOSIMINE</h3>
        <li>
          Auhinna loosimine toimub <span className="bold">01.03.2024</span>.
          Loosimine viiakse läbi loosimistarkvara stat.messenger.ee abil, mis
          võimaldab määrata loosimise täpse perioodi, auhinna koguse ning valib
          võitja määratud perioodi jooksul registreerunud osalejate seast juhuse
          alusel.
        </li>
        <li>
          Loosimisel osalevad kõik kampaania perioodil vastavalt kampaania
          tingimustele üle-Eesti kauplustes oste sooritanud kliendid.{' '}
        </li>
        <li>
          Võitjaga võetakse korraldaja esindaja poolt ühendust
          registreerimisvormis märgitud e-posti või telefoni teel võidu
          kinnitamiseks ning auhinna kättetoimetamise täpsustamiseks. Võitja
          nimi avaldatakse veebilehel{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt 3 tööpäeva
          jooksul pärast loosimise kuupäeva.
        </li>
        <li>
          Juhul, kui võitjaga ei ole õnnestunud ühendust saada hiljemalt
          08.03.2024 on korraldajal õigus auhinda mitte väljastada ja loosida
          see välja uuele võitjale.{' '}
        </li>
        <h3>MUUD TINGIMUSED</h3>
        <li>
          Võidetud auhinna kättesaamisega seotud kulutusi ei kompenseerita,
          saadud auhinda ei asendata teise auhinnaga ega hüvitata selle
          maksumust rahas. Kampaania korraldaja võtab vastutuse kõigi
          auhindadega seotud seadusjärgsete maksude tasumise eest.
        </li>
        <li>
          Auhinna kättesaamiseks peab võitja esitama oma isikut tõendava
          dokumendi ning võidu toonud ostutšeki.
        </li>
        <li>
          Kampaanias ei saa osaleda selle korraldamisega seotud inimesed ja
          nende pereliikmed.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui auhinda
          eeskirjade mittejärgimise tõttu välja ei anta, kui kampaanias osaleja
          esitatud andmed on väärad või ebatäpsed või kui võitjatega ei ole
          võimalik ühendust võtta kampaania korraldajatest mittesõltuvatel
          põhjustel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadustele. Vääramatu jõu (force majeure) ilmnemisel on
          korraldajal õigus tarbijamäng ühepoolselt katkestada, teatades sellest
          meedia vahendusel.{' '}
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5.korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a> .
          Laekunud pretensioonidele vastatakse kirjalikult 2 nädala jooksul.
        </li>
        <li>
          Lisateavet kampaania kohta saab tööpäevadel{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5.korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>
        </li>
        <h3>ANDMETE TÖÖTLEMINE</h3>
        <li>
          Isikuandmete vastutav töötleja on Fazer Eesti OÜ, Pärnu mnt 158/1,
          11317 Tallinn. Kampaanias osalejatel on õigus saada lisainfot Fazer
          Eesti OÜ poolt isikuandmete töötlemise kohta. Selleks tutvu lähemalt
          Fazer Eesti OÜ privaatsustingimustega siin:{' '}
          <a href="https://www.fazergroup.com/privacy/">
            https://www.fazergroup.com/privacy/
          </a>{' '}
        </li>
        <li>Võitja on veebilehel üleval kuni 31.03.2024.</li>
        <li>Kogutud andmeid säilitatakse kuni 06.04.2024.</li>
      </ol>
    </div>
  );
};

export default Et;
